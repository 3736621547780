/*
 * Marketplace specific configuration.
 *
 * Every filter needs to have following keys:
 * - id:     Unique id of the filter.
 * - label:  The default label of the filter.
 * - type:   String that represents one of the existing filter components:
 *           BookingDateRangeFilter, KeywordFilter, PriceFilter,
 *           SelectSingleFilter, SelectMultipleFilter.
 * - group:  Is this 'primary' or 'secondary' filter?
 *           Primary filters are visible on desktop layout by default.
 *           Secondary filters are behind "More filters" button.
 *           Read more from src/containers/SearchPage/README.md
 * - queryParamNames: Describes parameters to be used with queries
 *                    (e.g. 'price' or 'pub_amenities'). Most of these are
 *                    the same between webapp URLs and API query params.
 *                    You can't change 'dates', 'price', or 'keywords'
 *                    since those filters are fixed to a specific attribute.
 * - config: Extra configuration that the filter component needs.
 *
 * Note 1: Labels could be tied to translation file
 *         by importing FormattedMessage:
 *         <FormattedMessage id="some.translation.key.here" />
 *
 * Note 2: If you need to add new custom filter components,
 *         you need to take those into use in:
 *         src/containers/SearchPage/FilterComponent.js
 *
 * Note 3: If you just want to create more enum filters
 *         (i.e. SelectSingleFilter, SelectMultipleFilter),
 *         you can just add more configurations with those filter types
 *         and tie them with correct extended data key
 *         (i.e. pub_<key> or meta_<key>).
 */

import { getAllBrands } from '../util/api';

export let filters = [
  {
    id: 'category',
    label: 'Category',
    type: 'SelectMutlipleFilter',
    group: 'primary',
    queryParamNames: ['pub_category'],
    config: {
      // Schema type is enum for SelectSingleFilter
      schemaType: 'multi-enum',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'dog', label: 'Dog' },
        { key: 'cat', label: 'Cat' },
        { key: 'human', label: 'Human' },
        { key: 'smallAnimal', label: 'Small Animal' },
      ],
    },
  },
  {
    id: 'subcategory',
    label: 'Subcategory',
    type: 'SelectSingleFilter',
    group: 'primary',
    queryParamNames: ['pub_subcategory'],
    config: {
      // Schema type is enum for SelectSingleFilter
      schemaType: 'enum',

      // Specify if the field depends on the specific
      // defined filter.
      dependOn: 'category', // filter id

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'beds', label: 'Beds', category: 'all', collection: 'beddings' },
        { key: 'blankets', label: 'Blankets', category: 'all', collection: 'beddings' },
        { key: 'bowl', label: 'Bowl', category: 'all', collection: 'feeding' },
        { key: 'tray', label: 'Tray', category: 'all', collection: 'feeding' },
        { key: 'feeder', label: 'Feeder', category: 'all', collection: 'feeding' },
        {
          key: 'general-dog-treats',
          label: 'General Dog Treats',
          category: 'dog',
          collection: 'treats',
        },
        { key: 'dog-chews', label: 'Dog Chews', category: 'dog', collection: 'treats' },
        { key: 'dog-biscuits', label: 'Dog Biscuits', category: 'dog', collection: 'dog-biscuits' },
        {
          key: 'squeaky-and-plush-toys',
          label: 'Squeaky & Plush Toys',
          category: 'dog',
          collection: 'toys',
        },
        {
          key: 'rubber-and-rope-toys',
          label: 'Rubber & Rope Toys',
          category: 'dog',
          collection: 'toys',
        },
        {
          key: 'tough-toys-and-nylon-chews',
          label: 'Tough Toys & Nylon Chews',
          category: 'dog',
          collection: 'toys',
        },
        { key: 'activity-toys', label: 'Activity Toys', category: 'dog', collection: 'toys' },
        { key: 'dog-collars', label: 'Dog Collars', category: 'dog', collection: 'collars-leads' },
        {
          key: 'leads-and-harnesses',
          label: 'Leads & Harnesses',
          category: 'dog',
          collection: 'collars-leads',
        },
        {
          key: 'poop-bag-and-holder',
          label: 'Poop Bag & Holder',
          category: 'dog',
          collection: 'accessories',
        },
        {
          key: 'crates-and-carriers',
          label: 'Crates & Carriers',
          category: 'dog',
          collection: 'travel',
        },
        {
          key: 'tags-and-patches-clothes-and-accessories',
          label: 'Tags & Patches, Clothes & Accessories',
          category: 'dog',
          collection: 'clothing',
        },
        { key: 'dry-food', label: 'Dry Food', category: 'all', collection: 'food' },
        { key: 'wet-food', label: 'Wet Food', category: 'all', collection: 'food' },
        {
          key: 'fresh-raw-frozen-food',
          label: 'Fresh/Raw Frozen Food',
          category: 'all',
          collection: 'food',
        },
        {
          key: 'freeze-dried-food',
          label: 'Freeze Dried Food',
          category: 'all',
          collection: 'food',
        },
        { key: 'poop-bags', label: 'Poop Bags', category: 'dog', collection: 'accessories' },
        { key: 'shampoo', label: 'Shampoo', category: 'all', collection: 'grooming' },
        { key: 'supplements', label: 'Supplements', category: 'all', collection: 'health' },
        {
          key: 'training-pads',
          label: 'Training Pads',
          category: 'dog',
          collection: 'accessories',
        },

        { key: 'cat-house', label: 'Cat House', category: 'cat', collection: 'beddings' },
        { key: 'cat-scratchers', label: 'Cat Scratchers', category: 'cat', collection: 'toys' },
        {
          key: 'cat-toy-and-catnip',
          label: 'Cat Toy & Catnip',
          category: 'cat',
          collection: 'toys',
        },
        {
          key: 'harnesses-and-leash',
          label: 'Harnesses & Leash',
          category: 'cat',
          collection: 'collars-leads',
        },
        { key: 'cat-carriers', label: 'Cat Carriers', category: 'cat', collection: 'travel' },
        { key: 'collars', label: 'Collars', category: 'cat', collection: 'clothing' },
        {
          key: 'clothes-and-accessories',
          label: 'Clothes & Accessories',
          category: 'cat',
          collection: 'clothing',
        },
        { key: 'cat-litter', label: 'Cat Litter', category: 'cat', collection: 'litters' },
        {
          key: 'litter-trays-and-accessories',
          label: 'Litter Trays & Accessories',
          category: 'cat',
          collection: 'accessories',
        },
      ],
    },
  },
  {
    id: 'collection',
    label: 'Collection',
    type: 'SelectSingleFilter',
    group: 'primary',
    queryParamNames: ['pub_collection'],

    // Specify if the filter should be hidden from the search page.
    hideFromSearchPage: true,

    config: {
      // Schema type is enum for SelectSingleFilter
      schemaType: 'enum',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'food', label: 'Food' },
        { key: 'beddings', label: 'Beddings' },
        { key: 'feeding', label: 'Feeding' },
        { key: 'treats', label: 'Treats' },
        { key: 'toys', label: 'Toys' },
        { key: 'collars-leads', label: 'Collars & Leads' },
        { key: 'travel', label: 'Travel' },
        { key: 'health', label: 'Health' },
        { key: 'clothing', label: 'Clothing' },
        { key: 'grooming', label: 'Grooming' },
        { key: 'litters', label: 'Litters' },
        { key: 'accessories', label: 'Accessories' },
      ],
    },
  },
  {
    id: 'brand',
    label: 'Brand',
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_brand'],
    config: {
      // Schema type options: 'enum', 'multi-enum'
      // Both types can work so that user selects multiple values when filtering search results.
      // With "enum" the functionality will be OR-semantics (Nike OR Adidas OR Salomon)
      // With "multi-enum" it's possible to use both AND and OR semantics with searchMode config.
      schemaType: 'enum',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [],
    },
  },
  {
    id: 'color',
    label: 'Color',
    type: 'SelectSingleFilter',
    group: 'primary',
    queryParamNames: ['pub_color'],
    config: {
      // Schema type is enum for SelectSingleFilter
      schemaType: 'enum',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'red', label: 'Red', color: '#FF0000' },
        { key: 'green', label: 'Green', color: '#00FF00' },
        { key: 'blue', label: 'Blue', color: '#0000FF' },
        { key: 'yellow', label: 'Yellow', color: '#FFFF00' },
        { key: 'purple', label: 'Purple', color: '#800080' },
        { key: 'orange', label: 'Orange', color: '#FFA500' },
        { key: 'pink', label: 'Pink', color: '#FFC0CB' },
        { key: 'brown', label: 'Brown', color: '#A52A2A' },
        { key: 'cyan', label: 'Cyan', color: '#00FFFF' },
        { key: 'lime', label: 'Lime', color: '#00FF00' },
        { key: 'maroon', label: 'Maroon', color: '#800000' },
        { key: 'navy', label: 'Navy', color: '#000080' },
        { key: 'teal', label: 'Teal', color: '#008080' },
        { key: 'olive', label: 'Olive', color: '#808000' },
        { key: 'silver', label: 'Silver', color: '#C0C0C0' },
        { key: 'gray', label: 'Gray', color: '#808080' },
        { key: 'black', label: 'Black', color: '#000000' },
        { key: 'white', label: 'White', color: '#FFFFFF' },
      ],
    },
  },
  {
    id: 'size',
    label: 'Size',
    type: 'SelectSingleFilter',
    group: 'primary',
    queryParamNames: ['pub_size'],
    config: {
      // Schema type is enum for SelectSingleFilter
      schemaType: 'enum',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'xsmall', label: 'XSmall' },
        { key: 'small', label: 'Small' },
        { key: 'medium', label: 'Medium' },
        { key: 'large', label: 'Large' },
      ],
    },
  },
  {
    id: 'price',
    label: 'Price',
    type: 'PriceFilter',
    group: 'primary',
    // Note: PriceFilter is fixed filter,
    // you can't change "queryParamNames: ['price'],"
    queryParamNames: ['price'],
    // Price filter configuration
    // Note: unlike most prices this is not handled in subunits
    config: {
      min: 0,
      max: 1000,
      step: 5,
    },
  },
  {
    id: 'keyword',
    label: 'Keyword',
    type: 'KeywordFilter',
    group: 'primary',
    // Note: KeywordFilter is fixed filter,
    // you can't change "queryParamNames: ['keywords'],"
    queryParamNames: ['keywords'],
    // NOTE: If you are ordering search results by distance
    // the keyword search can't be used at the same time.
    // You can turn on/off ordering by distance from config.js file.
    config: {},
  },

  // Here is an example of multi-enum search filter.
  //
  // {
  //   id: 'amenities',
  //   label: 'Amenities',
  //   type: 'SelectMultipleFilter',
  //   group: 'secondary',
  //   queryParamNames: ['pub_amenities'],
  //   config: {
  //     // Schema type options: 'enum', 'multi-enum'
  //     // Both types can work so that user selects multiple values when filtering search results.
  //     // With "enum" the functionality will be OR-semantics (Nike OR Adidas OR Salomon)
  //     // With "multi-enum" it's possible to use both AND and OR semantics with searchMode config.
  //     schemaType: 'multi-enum',

  //     // Optional modes: 'has_all', 'has_any'
  //     // Note: this is relevant only for schema type 'multi-enum'
  //     // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
  //     searchMode: 'has_all',

  //     // "key" is the option you see in Flex Console.
  //     // "label" is set here for this web app's UI only.
  //     // Note: label is not added through the translation files
  //     // to make filter customizations a bit easier.
  //     options: [
  //       { key: 'towels', label: 'Towels' },
  //       { key: 'bathroom', label: 'Bathroom' },
  //       { key: 'swimming_pool', label: 'Swimming pool' },
  //       { key: 'barbeque', label: 'Barbeque' },
  //     ],
  //   },
  // },
];

export const sortConfig = {
  // Enable/disable the sorting control in the SearchPage
  active: true,

  // Note: queryParamName 'sort' is fixed,
  // you can't change it since Flex API expects it to be named as 'sort'
  queryParamName: 'sort',

  // Internal key for the relevance option, see notes below.
  relevanceKey: 'relevance',

  // Relevance key is used with keywords filter.
  // Keywords filter also sorts results according to relevance.
  relevanceFilter: 'keywords',

  // Keyword filter is sorting the results by relevance.
  // If keyword filter is active, one might want to disable other sorting options
  // by adding 'keyword' to this list.
  conflictingFilters: [],

  options: [
    { key: 'createdAt', label: 'Newest' },
    { key: '-createdAt', label: 'Oldest' },
    { key: '-price', label: 'Lowest price' },
    { key: 'price', label: 'Highest price' },

    // The relevance is only used for keyword search, but the
    // parameter isn't sent to the Marketplace API. The key is purely
    // for handling the internal state of the sorting dropdown.
    { key: 'relevance', label: 'Relevance', longLabel: 'Relevance (Keyword search)' },
  ],
};

export const listing = {
  // These should be listing details from public data with schema type: enum
  // SectionDetailsMaybe component shows these on listing page.
  enumFieldDetails: ['category', 'subcategory', 'brand', 'customBrand'],
};

export const discountCodeConfig = [
  { id: '6f642c35-8959-467b-8499-4cca55ba3748', code: 'NEWSLETTER10', discount: -10 },
];

// Function to update options asynchronously
const updateOptionsFromAPI = async () => {
  try {
    const list = await getAllBrands({ status: 'active' });

    // ensure result is sucessful and expect data structure
    if (list && list.data && Array.isArray(list.data) && list.data.length > 0) {
      const brandOptions = list.data
        // filter unexpected types
        .filter(brand => ['A-G', 'G-M', 'N-R', 'S-Z'].includes(brand.type))
        .map(brand => ({
          key: brand.key,
          label: brand.label,
          type: brand.type
        }))
        // sort by label A->Z
        .sort((a, b) => a.label.localeCompare(b.label));

      filters = filters.map(filter => {
        if (filter.id === 'brand') {
          filter.config.options = brandOptions;
        }
        return filter;
      });
    }
  } catch (error) {
    console.error('Error fetching brands:', error);
  }
};

export { updateOptionsFromAPI };
