import React, { useState } from 'react';
import { arrayOf, shape, string, func, object, bool } from 'prop-types';
import { NamedLink, IconArrowHead } from '../../../..';
import classNames from 'classnames';

// shared
import { isConflictingItems, VIEW_ALL_KEY } from '../TopbarDropdownMenu.helpers';
import DropdownSubItems from '../DropdownSubItems/DropdownSubItems';
import css from './Dropdown.module.css';

// Define constants for arrow position
const DROPDOWN_ITEM_ARROW_POSITION = 'right';

/**
 * Component that shows the single dropdown item.
 */
const DropdownListItem = props => {
  const { item, activeDropdownIndex, setActiveDropdownIndex, isBrand } = props;

  // Extract label and options from the item object
  const { label, options } = item;

  // Check if the current item has sub-items
  const hasOptions =
    options?.length > 0 && !(options.length === 1 && options.find(o => o.key === VIEW_ALL_KEY));

  // Check if the current item is conflicting
  const conflictingItems = isConflictingItems(item.key);

  // Define the redirect params
  const toSearch = isBrand
    ? `pub_brand=${item.key}`
    : conflictingItems
    ? `pub_category=${item.category}`
    : `pub_category=${item.category}&pub_collection=${item.key}`;

  // Check if sub-items should be shown
  const showSubItems = activeDropdownIndex === item.key && hasOptions;

  // Create and show item arrow
  const itemArrow = hasOptions ? (
    <IconArrowHead className={css.dropdownListItemArrow} direction={DROPDOWN_ITEM_ARROW_POSITION} />
  ) : null;

  // Create the sub-items component
  const subItems = showSubItems ? <DropdownSubItems options={options} /> : null;

  return (
    <li className={css.dropdownListItem} onMouseEnter={() => setActiveDropdownIndex(item.key)}>
      <NamedLink
        className={css.dropdownListItemLink}
        name="SearchPage"
        to={{
          search: toSearch,
        }}
      >
        <span className={css.itemBorder} />
        {label}
        {itemArrow}
      </NamedLink>
      {subItems}
    </li>
  );
};

DropdownListItem.defaultProps = {
  item: null,
  activeDropdownIndex: null,
  setActiveDropdownIndex: null,
  isBrand: false,
};

DropdownListItem.propTypes = {
  item: object,
  activeDropdownIndex: string,
  setActiveDropdownIndex: func.isRequired,
  isBrand: bool.isRequired,
};

const BrandDropdownList = props => {
  const { options, activeDropdownIndex, setActiveDropdownIndex } = props;

  const AGbrands = options.filter(i => i.type === 'A-G');
  const GMbrands = options.filter(i => i.type === 'G-M');
  const NRbrands = options.filter(i => i.type === 'N-R');
  const SZbrands = options.filter(i => i.type === 'S-Z');

  const brands = [AGbrands, GMbrands, NRbrands, SZbrands];
  return (
    <div className={css.dropdownGridList}>
      {brands
        .map((b, index) => {
        return (
          <ul key={index} className={css.dropdownList}>
            <li className={css.dropdownListItem}>
              <span className={css.dropdownListItemType}>{b[0]?.type||''}</span>
            </li>
            {b.map(i => {
              return (
                <DropdownListItem
                  key={i.key}
                  item={i}
                  activeDropdownIndex={activeDropdownIndex}
                  setActiveDropdownIndex={setActiveDropdownIndex}
                  isBrand
                />
              );
            })}
          </ul>
        );
      })}
    </div>
  );
};

/**
 * Component that shows the dropdown list.
 */
const DropdownList = props => {
  const { option, options, activeDropdownIndex, setActiveDropdownIndex } = props;
  const isDogItem = option.key === 'dog';
  return (
    <ul className={css.dropdownList}>
      {options.map(i => {
        const isLittersItem = i.key === 'litters';
        if (isDogItem && isLittersItem) {
          return null;
        }
        return (
          <DropdownListItem
            key={i.key}
            item={i}
            activeDropdownIndex={activeDropdownIndex}
            setActiveDropdownIndex={setActiveDropdownIndex}
          />
        );
      })}
    </ul>
  );
};

DropdownList.defaultProps = {
  options: [],
  activeDropdownIndex: null,
  setActiveDropdownIndex: null,
};

DropdownList.propTypes = {
  options: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ),
  activeDropdownIndex: string,
  setActiveDropdownIndex: func.isRequired,
};

/**
 * Component that shows the dropdown.
 */
const Dropdown = props => {
  const { rootClassName, className, option, isBrand, ...rest } = props;

  const classes = classNames(rootClassName || className, css.root, {
    [css.brandRoot]: isBrand,
  });

  // Define the active dropdown index
  const [activeDropdownIndex, setActiveDropdownIndex] = useState(null);

  return (
    <div className={classes}>
      {isBrand ? (
        <BrandDropdownList
          activeDropdownIndex={activeDropdownIndex}
          setActiveDropdownIndex={setActiveDropdownIndex}
          {...rest}
        />
      ) : (
        <DropdownList
          option={option}
          activeDropdownIndex={activeDropdownIndex}
          setActiveDropdownIndex={setActiveDropdownIndex}
          {...rest}
        />
      )}
    </div>
  );
};

DropdownSubItems.defaultProps = {
  rootClassName: null,
  className: null,
  isBrand: false,
};

DropdownSubItems.propTypes = {
  rootClassName: string,
  className: string,
  option: object,
  isBrand: bool.isRequired,
};

export default Dropdown;
